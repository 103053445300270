export const perm_list = { async list() {
    let items = [
      {
        parent_id: 'Dashboard',
        parent_name: 'Dashboard',
            name: 'Dashboard',
            selected: [],
            api: 'dashboard',
            action: [
                {label: 'View', id: 1, url: ['/home/dashboard']},
            ]
      },
      {
        parent_id: 'Bills',
        parent_name: 'Bills',
        name: 'Purchased Parking Tokens',
        selected: [],
        api: 'ptpurchasedparkingtokens',
        action: [
            {label: 'View', id: 1, url: ['/home/bills/purchasedparkingtokens', '/api/parkingtoken/view/report/purchase']},
        ]
      },
      {
        parent_id: 'Bills',
            parent_name: 'Bills',
        name: 'Purchased Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/bills/purchasedvaluepass']},
        ]
      },
      {
        parent_id: 'Bills',
            parent_name: 'Bills',
        name: 'Parking',
        selected: [],
        api: 'parking',
        action: [
            {label: 'View', id: 1, url: ['/home/bills/parking']},
        ]
      },
      {
        parent_id: 'Bills',
            parent_name: 'Bills',
        name: 'BayLock Parking',
        selected: [],
        api: 'parking',
        action: [
            {label: 'View', id: 1, url: ['/home/bills/baylockparking']},
        ]
      },
      {
        parent_id: 'ParkingEntry',
        parent_name: 'Parking Entry',
            name: 'Gated Parking',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/gatedparking', '/api/parking/ticket/view/inparking/all', '/api/parking/ticket/view/updateticketdetails']},
                {label: 'View Details', id: 2, url: ['/home/parkingentry/gatedparking/details']},
                {label: 'Create Manual Exit', id: 3, url: ['/home/parkingentry/gatedparking/create', '/api/parking/ticket/update/manualexit']},
                {label: 'Create Manual Entry', id: 4, url: ['/home/parkingentry/gatedparking/createentry', '/api/parking/ticket/update/manualentry']},
            ]
      },
      {
        parent_id: 'ParkingEntryLpr',
        parent_name: 'LPR Parking',
            name: 'LPR Parking',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/lpr/parking', '/api/parking/lpr/view/inparking/all']},
                {label: 'View Details', id: 2, url: ['/home/parkingentry/lprparking/details']},
                {label: 'Create Manual Exit', id: 3, url: ['/home/parkingentry/lprparking/create', '/api/lpr/process/manualexit']},
                {label: 'Create LPR Exit', id: 5, url: ['/home/parkingentry/lprparking/createexit', '/api/do/lpr/trigger/exit']},
                {label: 'Create LPR Entry', id: 6, url: ['/home/parkingentry/lprparking/entry', '/api/do/lpr/carplateno/manual']},
                {label: 'Update Plate No.', id: 7, url: ['/home/lpr/updateplateno', '/api/do/lpr/carplateno/update']},
            ]
      },
      {
        parent_id: 'ParkingEntryLpr',
        parent_name: 'LPR Parking',
            name: 'LPR Images',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/lpr/images/all']},
            ]
      },
      {
        parent_id: 'ParkingEntryLpr',
        parent_name: 'LPR Parking',
            name: 'LPR Unknown',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/lpr/images/unknown']},
            ]
      },
      {
        parent_id: 'ParkingEntry',
        parent_name: 'Parking Entry',
            name: 'BayLock Parking',
            selected: [],
            api: 'parkingentry',
            action: [
                {label: 'View List', id: 1, url: ['/home/parkingentry/baylockparking', '/api/parkingwheellock/trxn/inparking']},
                {label: 'View Details', id: 2, url: ['/home/parkingentry/baylockparking/details', '/api/parkingwheellock/process/:ParkingWheelLockID/updatewldetails']},
                {label: 'Create Manual Exit', id: 3, url: ['/home/parkingentry/baylockparking/create', '/api/parkingwheellock/process/manualexit']},
            ]
      },
      {
        parent_id: 'Subscriber',
        parent_name: 'Subscriber',
            name: 'Season',
            selected: [],
            api: 'season',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/season', '/api/season/seasonaccess/getlist/search']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/season/viewdetails']}, //new
                {label: 'Update Status', id: 3, url: ['/home/subscriber/season/updatestatus', '/api/season/seasonaccess/updatestatus']},
            ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Car Park',
        selected: [],
        api: 'config_carpark',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/carpark', '/api/config_carpark/view/search']},
            {label: 'Mapping', id: 2, url: ['/home/carpark/carpark/mapping', '/api/config_carpark/update/mappingid/item']},
            {label: 'Add', id: 3, url: ['/home/carpark/carpark/create', '/api/config_carpark/register/new']},
            {label: 'Update', id: 4, url: ['/home/carpark/carpark/update', '/api/config_carpark/update/details/item', '/api/config_carpark/update/headerimage/item', '/api/config_carpark/update/footerimage/item',
                                            '/api/config_carpark/update/logoimage/item', '/api/config_carpark/update/generaldocumentimage/item', '/api/config_carpark/update/bayprefix/item']},
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Car Park Rate',
        selected: [],
        api: 'ratecard',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/ratesetup']},
            {label: 'Add', id: 2, url: ['/home/carpark/ratesetup/create', '/api/prate_ratecard/create/new']},
            {label: 'Update', id: 3, url: ['/home/carpark/ratesetup/update', '/api/prate_ratecard/update/main/item',
                                            '/api/prate_ratecard/update/new/slotid', '/api/prate_ratecard/update/existing/slotid', '/api/prate_ratecard/update/chosendefaultratecardforcarpark']},
            {label: 'Delete', id: 4, url: ['/home/carpark/ratesetup/delete', '/api/prate_ratecard/delete/item']},
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Special Car Park Rate',
        selected: [],
        api: 'ratecard',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/specialrate', '/api/specialratevalidation/list/available/byselection']},
            {label: 'Add', id: 2, url: ['/home/carpark/specialrate/create', '/api/specialratevalidation/newspecial']},
            {label: 'Update', id: 3, url:['/home/carpark/specialrate/update', '/api/specialratevalidation/:specialRateID/update']}
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Bays',
        selected: [],
        api: 'config_carparkbay',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/bays', '/api/config_carparkbay/view/bycarparkid']},
            {label: 'Add', id: 2, url: ['/home/carpark/bays/create', '/api/config_carparkbay/update/item/new']},
            {label: 'Update', id: 3, url: ['/api/config_carparkbay/update/item/existing']},
            {label: 'Delete', id: 4, url: ['/api/config_carparkbay/delete/item']},
        ]
      },
      {
        parent_id: 'CarPark',
            parent_name: 'Car Park',
        name: 'Find My Car',
        selected: [],
        api: 'config_carparkbay',
        action: [
            {label: 'View', id: 1, url: ['/home/carpark/findmycar', '/api/findmycar/qrdetails/listall', '/api/findmycar/qrdetails/*']},
            {label: 'View Details', id: 2, url: ['/home/carpark/findmycar/details']},
            {label: 'Download QR', id: 3, url: ['/home/carpark/findmycar/download']},
            {label: 'Add', id: 4, url: ['/home/carpark/findmycar/create', '/api/findmycar/qrdetails/new']},
            {label: 'Update', id: 5, url: ['/api/findmycar/qrdetails/:qrcodeid/update']},
            {label: 'Delete', id: 6, url: ['/api/findmycar/qrdetails/:qrcodeid/delete']},
        ]
      },
      {
        parent_id: 'BoomGateC',
            parent_name: 'Boom Gate - Controller',
        name: 'Controller',
        selected: [],
        api: 'config_controller',
        action: [
            {label: 'View List', id: 1, url: ['/home/boomgate/controller/controller', '/api/gatecontroller/view/bygatecontrollerid']},
        ]
      },
      {
        parent_id: 'BoomGate',
            parent_name: 'Boom Gate',
        name: 'Boom Gate',
        selected: [],
        api: 'config_boomgate',
        action: [
            {label: 'View List', id: 1, url: ['/home/boomgate/boomgate', '/api/gate/view/bygateid', '/api/gate/update']},
            {label: 'View Details', id: 2, url: ['/home/boomgate/boomgate/details']},
            {label: 'Update Is Entry', id: 5, url: ['/home/boomgate/boomgate/updateisentry']},
        ]
      },
      {
        parent_id: 'BayLock',
            parent_name: 'BayLock',
        name: 'Controller',
        selected: [],
        api: 'config_controller',
        action: [
            {label: 'View List', id: 1, url: ['/home/baylock/controller', '/api/wheellockcontroller/update']},
            {label: 'View Details', id: 2, url: ['/home/baylock/controller/details']},
            //{label: 'Reset Token', id: 3, url: ['/api/wheellockcontroller/regenerateuuid']},
            //{label: 'Action', id: 4, url: ['/home/baylock/controller/action', '/api/wheellockcontroller/:WLControllerID/manualsync', '/api/wheellockcontroller/:WLControllerID/resetdelta']},
            {label: 'Add', id: 5, url: ['/home/baylock/controller/create', '/api/wheellockcontroller/create']},
            {label: 'Update', id: 6, url: ['/home/baylock/controller/update']},
            {label: 'Update Car Park Details', id: 7, url: ['/home/baylock/controller/updatecpd']},
        ]
      },
      {
        parent_id: 'BayLock',
            parent_name: 'BayLock',
        name: 'Mainboard',
        selected: [],
        api: 'config_mainboard',
        action: [
            {label: 'View List', id: 1, url: ['/home/baylock/mainboard', '/api/wheellockmainboard/update']},
            {label: 'View Details', id: 2, url: ['/home/baylock/mainboard/details']},
            {label: 'Add', id: 3, url: ['/home/baylock/mainboard/create', '/api/wheellockmainboard/create']},
            {label: 'Update', id: 4, url: ['/home/baylock/mainboard/update']},
            {label: 'Update Controller Details', id: 5, url: ['/home/baylock/mainboard/updatecpd']},
        ]
      },
      {
        parent_id: 'BayLock',
            parent_name: 'BayLock',
        name: 'BayLock',
        selected: [],
        api: 'config_wheellock',
        action: [
            {label: 'View List', id: 1, url: ['/home/baylock/baylock', '/api/wheellocklock/view/all', '/api/wheellocklock/update']},
            {label: 'View Details', id: 2, url: ['/home/baylock/baylock/details']},
            //{label: 'Reset Token', id: 3, url: ['/api/wheellocklock/regenerateuuid']},
            {label: 'Action', id: 4, url: ['/home/baylock/baylock/action']},
            {label: 'Add', id: 5, url: ['/home/baylock/baylock/create', '/api/wheellocklock/create']},
            {label: 'Update', id: 6, url: ['/home/baylock/baylock/update']},
            {label: 'Update Mainboard Details', id: 7, url: ['/home/baylock/baylock/updatecpd']},
        ]
      },
      {
        parent_id: 'ValuePass',
            parent_name: 'Value Pass',
        name: 'Value Pass',
        selected: [],
        api: 'valuepass',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/valuepass/valuepass', '/api/valuepass/list/all', '/api/valuepass/vpownershipbyvpproductid/list/all', '/api/valuepass/list/item/tnc/', '/api/valuepass/list/item/tnc/:valuepassproductid', '/api/valuepass/vppurchasebyvpproductid/list/all']},
            {label: 'Add', id: 2, url: ['/home/marketing/valuepass/valuepass/create', '/api/valuepass/create']},
            {label: 'Update', id: 3, url: ['/home/marketing/valuepass/valuepass/update', '/api/valuepass/update', '/api/valuepass/addimage', '/api/valuepass/deleteimage',
                                            '/api/valuepass/generatepurchasecode']},
        ]
      },
      {
        parent_id: 'ValuePass',
            parent_name: 'Value Pass',
        name: 'Value Pass Units',
        selected: [],
        api: 'valuepassunits',
        action: [
            {label: 'View', id: 1, url: ['/home/marketing/valuepass/valuepassunits']},
        ]
      },
      {
        parent_id: 'MerchantReward',
            parent_name: 'Services - Merchant Reward',
        name: 'Merchant Rewards',
        selected: [],
        api: 'merchantrewards',
        action: [
            {label: 'View', id: 1, url: ['/home/services/merchantreward/merchantrewards', '/api/merchantrewardslot/view/myslot']},
            {label: 'Add', id: 2, url: ['/home/services/merchantreward/merchantrewards/create', '/api/merchantrewardslot/purchase', '/api/merchantrewardslot/purchase/checktransactionstatus/:paymentgateway/:reftocheckstatus']},
            {label: 'Update', id: 3, url: ['/home/services/merchantreward/merchantrewards/update', '/api/merchantrewardslot/update', '/api/merchantrewardslot/mobiledisplaystatus/update', '/api/merchantrewardslot/review/approvalstatus/update']},
        ]
      },
      {
        parent_id: 'MerchantReward',
            parent_name: 'Services - Merchant Reward',
        name: 'Collected Promo Vouchers',
        selected: [],
        api: 'merchantrewardslots',
        action: [
            {label: 'View', id: 1, url: ['/home/services/merchantreward/collectedpromovouchers', '/api/merchantrewardpromo/view/collectedpromo']}
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/parking', '/api/parking/ticket/view/transactions/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Special Rate Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/specialrateparking']},
            {label: 'View Subscriber Info', id: 2, url: ['/home/transaction/mobilesub/specialrateparking/viewdetails']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Manual Exit',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/manualexit', '/api/parking/ticket/view/transactions/manualexit/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Manual Entry',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/manualentry', '/api/parking/ticket/view/transactions/manualentry/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'LPR Exit',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/lprexit', '/api/lpr/trigger/transaction']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'LPR Manual Entry',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/lprentry', '/api/lpr/trigger/entry/transaction']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Update Plate',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/updateplate', '/api/lpr/trigger/updateplate/transaction']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Purchased Value Pass',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/purchasedvaluepass', '/api/valuepass/vppurchasebytransaction/list/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Value Pass Units',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/valuepassunits']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Transferred Value Pass',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/valuepasstransfer', '/api/valuepass/vpunittransfer/list/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'Used Value Pass',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/usedvaluepass', '/api/valuepass/vpunitutilization/list/all']},
        ]
      },
      {
        parent_id: 'Transaction',
            parent_name: 'Transaction - Mobile Subscriber',
        name: 'History',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/mobilesub/history', '/api/transaction/customerhistory/view/all', '/api/transaction/customerhistory/view/bytrxrefloginmobileid']},
        ]
      },
      {
        parent_id: 'TransactionCS',
            parent_name: 'Transaction - Merchant Rewards',
        name: 'Purchased Merchant Rewards',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/merchantrewards/purchasedmerchantrewards', '/api/merchantreward/merchantrewardslotpurchase/list/all']},
        ]
      },
      {
        parent_id: 'TransactionCS',
            parent_name: 'Transaction - Merchant Rewards',
        name: 'Collected Promo Vouchers',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/merchantrewards/collectedpromovouchers', '/api/merchantreward/merchantrewardcollectpromo/list/all']},
        ]
      },
      {
        parent_id: 'TransactionCS',
            parent_name: 'Transaction - Merchant Rewards',
        name: 'Redeemed Promo Vouchers',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/merchantrewards/redeemedpromovouchers', '/api/merchantreward/merchantrewardredeempromo/list/all']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'All',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/allparking', '/api/parkingwheellock/trxn/all']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'App Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/appparking', '/api/parkingwheellock/trxn/mobile']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Pole Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/poleparking', '/api/parkingwheellock/trxn/pole']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'Web Payment',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/webpayment', '/api/wl/payee/transaction/view/all']},
        ]
      },
      {
        parent_id: 'TransactionBP',
            parent_name: 'Transaction - BayLock Parking',
        name: 'No Payment',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/baylockparking/nopayment', '/api/parkingwheellock/trxn/parknrun']},
        ]
      },
      {
        parent_id: 'TransactionLPR',
            parent_name: 'Transaction - LPR WEB',
        name: 'Payment',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/transaction/lprweb/payment', '/api/payee/transaction/view/all']}
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Casual Parking',
        selected: [],
        api: 'pcasualparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/casualparking']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Special Rate Parking',
        selected: [],
        api: 'pcasualparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/specialrateparking']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Season Parking',
        selected: [],
        api: 'seasonparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/seasonparking', '/api/parking/transactions/snatchseason/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Manual Exit',
        selected: [],
        api: 'manualexitreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/manualexit', '/api/parking/transactions/snatchmanualexit/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Manual Entry',
        selected: [],
        api: 'manualentryreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/manualentry', '/api/parking/transactions/snatchmanualentry/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'LPR Exit',
        selected: [],
        api: 'lprexitreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/lprexit', '/api/lpr/trigger/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'LPR Manual Entry',
        selected: [],
        api: 'lprmanualentryreport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/lprentry', '/api/lpr/trigger/entry/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'Update Plate',
        selected: [],
        api: 'updateplatereport',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/updateplate', '/api/lpr/trigger/updateplate/report']},
        ]
      },
      {
        parent_id: 'ReportP',
            parent_name: 'Report - Parking',
        name: 'All Parking',
        selected: [],
        api: 'allparking',
        action: [
            {label: 'View', id: 1, url: ['/home/report/parking/allparking', '/api/parking/transactions/all/report']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'All',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/allparking', '/api/parkingwheellock/report/all']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'App Casual Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/appparking']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'Pole Casual Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/poleparking', '/api/parkingwheellock/report/pole']},
        ]
      },
      {
        parent_id: 'ParkingBP',
            parent_name: 'Report - BayLock Parking',
        name: 'Web Casual Parking',
        selected: [],
        api: 'mobilesub',
        action: [
            {label: 'View', id: 1, url: ['/home/report/baylockparking/webpayment', '/api/wl/payee/report/view/all']},
        ]
      },
      {
        parent_id: 'ReportVP',
            parent_name: 'Report - Value Pass',
        name: 'Purchased Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/valuepass/purchasedvaluepass']},
        ]
      },
      {
        parent_id: 'ReportVP',
            parent_name: 'Report - Value Pass',
        name: 'Transferred Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/valuepass/transferredvaluepass', '/api/valuepass/vpunittransfer/report']},
        ]
      },
      {
        parent_id: 'ReportVP',
            parent_name: 'Report - Value Pass',
        name: 'Used Value Pass',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/valuepass/usedvaluepass', '/api/valuepass/vpunitutilization/report']},
        ]
      },
      {
        parent_id: 'ReportMR',
            parent_name: 'Report - Merchant Rewards',
        name: 'Purchased Merchant Rewards',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/merchantrewards/purchasedmerchantrewards', '/api/merchantreward/merchantrewardslotpurchase/report']},
        ]
      },
      {
        parent_id: 'ReportMR',
            parent_name: 'Report - Merchant Rewards',
        name: 'Collected Promo Vouchers',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/merchantrewards/collectedpromovouchers', '/api/merchantreward/merchantrewardcollectpromo/report']},
        ]
      },
      {
        parent_id: 'ReportMR',
            parent_name: 'Report - Merchant Rewards',
        name: 'Redeemed Promo Vouchers',
        selected: [],
        api: 'ptvp',
        action: [
            {label: 'View', id: 1, url: ['/home/report/merchantrewards/redeemedpromovouchers', '/api/merchantreward/merchantrewardredeempromo/report']},
        ]
      },
      {
        parent_id: 'ReportOR',
            parent_name: 'Report - Operator Remittance',
        name: 'All Products',
        selected: [],
        api: 'orallproducts',
        action: [
            {label: 'View', id: 1, url: ['/home/report/operatorremittance/allproducts', '/api/operatorremittance/all/search']},
        ]
      },
      {
        parent_id: 'ReportLprWeb',
            parent_name: 'Report - LPR WEB',
        name: 'Payment',
        selected: [],
        api: 'pg',
        action: [
            {label: 'View', id: 1, url: ['/home/report/lprweb/payment', '/api/payee/report/view/all']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'EInvoice',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: ['/home/finance/lhdn/settings']},
            {label: 'Update', id: 2, url: ['/home/finance/lhdn/update', '/api/config_einvoice/supplier/:supplierID/update']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'Consolidated Bills',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: [process.env.VUE_APP_LHDN_SUBMITTED_DOC, '/api/einvoice/supplier/consolidated_document/list']},
        ]
      },
      {
        parent_id: 'FinanceEInvoice',
            parent_name: 'Finance - EInvoice',
        name: 'E-Invoice Documents',
        selected: [],
        api: 'config_users',
        action: [
            {label: 'View', id: 1, url: [process.env.VUE_APP_LHDN_EINVOICE_DOCUMENT, '/api/einvoice/supplier/document/list']},
            {label: 'View Bill', id: 2, url: [process.env.VUE_APP_LHDN_EINVOICE_DOCUMENT_BILL, '/api/einvoice/document/:billId/view']},
        ]
      },
      /*
      {
        parent_id: 'Subscriber',
        parent_name: 'Subscriber',
            name: 'Mobile',
            selected: [],
            api: 'mobile',
            action: [
                {label: 'View List', id: 1, url: ['/home/subscriber/mobile', '/api/view/customer/list/subscriberdriver']},
                {label: 'View Details', id: 2, url: ['/home/subscriber/mobile/viewdetails']}, //new
                {label: 'Create', id: 3, url: ['/home/subscriber/mobile/create', '/api/admin/customer/createaccount/subscriberdriver']},
                {label: 'Update Status', id: 4, url: ['/home/subscriber/mobile/updatestatus', '/api/admin/customer/lockaccount']},
                {label: 'View PIN', id: 5, url: ['/home/subscriber/mobile/viewpin', '/api/accesspin/getaccesspinbyloginmobileid']},
            ]
      },
      */
    ]

    return items;
}
}